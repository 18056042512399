import React from 'react'
import { GoogleMap, Marker, DirectionsService, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';
import { TransitLayer } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '100vh',
};

const center = {
  lat: 40.7484,
  lng: -73.985428
};

interface TrainMapProps {
  reveal: boolean
  originLat: number
  originLng: number
  destinationLat: number
  destinationLng: number
}

function TrainMap( {
  reveal,
  originLat,
  originLng,
  destinationLat,
  destinationLng
} : TrainMapProps) {

  const origin = {
    lat: originLat,
    lng: originLng
  }
  const destination = {
    lat: destinationLat,
    lng: destinationLng
  }

  const [map, setMap] = React.useState(null);
  const [directions, setDirections] = React.useState(null);
  const [directionsSet, setDirectionsSet] = React.useState(false);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAfoT3hbjMGo1RRgPClqMHHtitBCW8Itok"
  })

  const onLoad = React.useCallback((map: any) => {
    setMap(map)
  }, [])
  
  const onUnmount = React.useCallback((map: any) => {
    setMap(null)
  }, [])

  const directionsCallback = (response: any, status: any) => {
    console.log(response)

    if (response !== null && directionsSet === false) {
      if (response.status === 'OK') {
        setDirections(response);  
        setDirectionsSet(true);
        
        
      } else {
        console.log('response: ', response)
      }
    }
    console.log(response)
  }


  


  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={
            {
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                styles: [
                    {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }]
                    },
                    {
                        featureType: "road",
                        elementType: "labels",
                        stylers: [{ visibility: "off" }]
                    },
                    {
                        featureType: "administrative",
                        stylers: [{ visibility: "off" }]
                    },
                    {
                        featureType: 'transit',
                        stylers: [{ visibility: "off" }]
                    },
                    {
                        featureType: 'transit.line',
                        stylers: [{ visibility: reveal? "on" :"off" }]
                    },
                    {
                        featureType: 'transit.station',
                        elementType: 'labels.icon',
                        stylers: [{ visibility: "on" }]
                    },
                    {
                        featureType: 'landscape.man_made',
                        stylers: [{ visibility: "off" }]
                    }
                ]
            }
        }
      >
        <TransitLayer onLoad={onLoad}/>
        { directions === null &&
          <DirectionsService
            // required
            options={{
              origin: origin,
              destination: destination,
              provideRouteAlternatives: true,
              transitOptions: {
                modes: [google.maps.TransitMode.SUBWAY, google.maps.TransitMode.TRAIN, google.maps.TransitMode.RAIL, google.maps.TransitMode.TRAM]
              },
              travelMode: google.maps.TravelMode.TRANSIT
            }}
            // required
            callback={directionsCallback}
            // // optional
            // onLoad={directionsService => {
            //   console.log('DirectionsService onLoad directionsService: ', directionsService)
            // }}
            // // optional
            // onUnmount={directionsService => {
            //   console.log('DirectionsService onUnmount directionsService: ', directionsService)
            // }}
          />
        }
        { 
          directions !== null && reveal && (
            <DirectionsRenderer
              // required
              options={{ 
                directions: directions
              }}
              // // optional
              // onLoad={directionsRenderer => {
              //   console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
              // }}
              // // optional
              // onUnmount={directionsRenderer => {
              //   console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
              // }}
            />
          )
        }
        { !reveal && (
        <>
          <Marker label={"A"}
          position={origin}/>
          <Marker position={destination}
          label={"B"}/>
        </>
        )}
        
      </GoogleMap>
  ) : <></>
}
export default React.memo(TrainMap)