import * as React from "react"
import {
  SimpleGrid,
  Container,
  OrderedList,
  ListItem,
  Button,
  Flex,
  ChakraProvider,
  Text,
  theme,
  VStack,
} from "@chakra-ui/react"
import TrainMap from "./TrainMap"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
// @ts-ignore
import randomPointsOnPolygon from 'random-points-on-polygon';
import * as turf from '@turf/turf'
const numberOfPoints = 2;
const NYCPoly = turf.polygon([[
  [
    -73.9287071,
    40.8761965
  ],
  [
    -73.9417534,
    40.8499722
  ],
  [
    -73.9618377,
    40.8137345
  ],
  [
    -73.980732,
    40.7879502
  ],
  [
    -74.0026466,
    40.7602116
  ],
  [
    -74.0184838,
    40.7668997
  ],
  [
    -74.0115818,
    40.7782647
  ],
  [
    -74.0342411,
    40.7872333
  ],
  [
    -74.0645666,
    40.727265
  ],
  [
    -74.0660555,
    40.7006902
  ],
  [
    -74.0543825,
    40.6960049
  ],
  [
    -74.0403063,
    40.7078476
  ],
  [
    -74.0523226,
    40.7127922
  ],
  [
    -74.0351565,
    40.7131826
  ],
  [
    -74.0212004,
    40.7665888
  ],
  [
    -74.0194847,
    40.7660565
  ],
  [
    -74.0028734,
    40.7595995
  ],
  [
    -74.0067551,
    40.7531342
  ],
  [
    -74.0093178,
    40.7350261
  ],
  [
    -74.0102529,
    40.7284335
  ],
  [
    -74.0110483,
    40.7257491
  ],
  [
    -74.0124464,
    40.7201685
  ],
  [
    -74.0130464,
    40.7182545
  ],
  [
    -74.0165333,
    40.7185635
  ],
  [
    -74.0174545,
    40.7137189
  ],
  [
    -74.0160812,
    40.7134912
  ],
  [
    -74.0163816,
    40.7120111
  ],
  [
    -74.0177871,
    40.7121982
  ],
  [
    -74.0186828,
    40.7080581
  ],
  [
    -74.0176743,
    40.7080011
  ],
  [
    -74.0179503,
    40.7073115
  ],
  [
    -74.018476,
    40.7067096
  ],
  [
    -74.0190446,
    40.7067503
  ],
  [
    -74.0185404,
    40.7048227
  ],
  [
    -74.0171119,
    40.7047587
  ],
  [
    -74.0152011,
    40.7013642
  ],
  [
    -74.0096329,
    40.7019824
  ],
  [
    -74.0046525,
    40.7051637
  ],
  [
    -74.0011075,
    40.7071792
  ],
  [
    -73.9994875,
    40.7079825
  ],
  [
    -73.9943251,
    40.7039001
  ],
  [
    -73.9948049,
    40.7029233
  ],
  [
    -73.998457,
    40.6956141
  ],
  [
    -74.0016852,
    40.6859842
  ],
  [
    -74.0143809,
    40.6762248
  ],
  [
    -74.0013347,
    40.6707892
  ],
  [
    -73.9953694,
    40.6761272
  ],
  [
    -73.9911208,
    40.6767456
  ],
  [
    -73.9888892,
    40.680586
  ],
  [
    -73.9870439,
    40.6821481
  ],
  [
    -73.9862714,
    40.6812369
  ],
  [
    -73.9880738,
    40.677787
  ],
  [
    -73.9893613,
    40.6752158
  ],
  [
    -73.9880309,
    40.6741417
  ],
  [
    -73.9888892,
    40.6729374
  ],
  [
    -73.9999185,
    40.6664925
  ],
  [
    -73.9962952,
    40.6633659
  ],
  [
    -74.0325157,
    40.6368621
  ],
  [
    -74.0348053,
    40.615451
  ],
  [
    -74.0357655,
    40.6095136
  ],
  [
    -74.0310836,
    40.6065495
  ],
  [
    -74.0221024,
    40.6044065
  ],
  [
    -74.0122747,
    40.6018651
  ],
  [
    -74.0037366,
    40.5970943
  ],
  [
    -73.9985847,
    40.5944682
  ],
  [
    -73.994379,
    40.5904599
  ],
  [
    -73.996439,
    40.5863535
  ],
  [
    -73.9990997,
    40.5841373
  ],
  [
    -73.9959631,
    40.5826433
  ],
  [
    -73.9918432,
    40.5843707
  ],
  [
    -73.9892254,
    40.5853811
  ],
  [
    -73.9868042,
    40.583287
  ],
  [
    -73.9859888,
    40.5817225
  ],
  [
    -73.9859888,
    40.5806632
  ],
  [
    -73.9873624,
    40.578792
  ],
  [
    -73.9903235,
    40.5782216
  ],
  [
    -74.0059681,
    40.5815
  ],
  [
    -74.0111823,
    40.5784524
  ],
  [
    -74.0106912,
    40.5748293
  ],
  [
    -74.0069576,
    40.5738351
  ],
  [
    -74.0021511,
    40.5724823
  ],
  [
    -74.0012284,
    40.5709502
  ],
  [
    -73.9942718,
    40.5709036
  ],
  [
    -73.9726498,
    40.5732699
  ],
  [
    -73.9459455,
    40.575772
  ],
  [
    -73.9323843,
    40.5763261
  ],
  [
    -73.9331138,
    40.5804005
  ],
  [
    -73.9432542,
    40.5799358
  ],
  [
    -73.9538543,
    40.5814025
  ],
  [
    -73.9541283,
    40.5835166
  ],
  [
    -73.930722,
    40.6070862
  ],
  [
    -73.9185153,
    40.6322279
  ],
  [
    -73.8909959,
    40.6514737
  ],
  [
    -73.8420966,
    40.6636976
  ],
  [
    -73.8068586,
    40.6647633
  ],
  [
    -73.8055282,
    40.6635262
  ],
  [
    -73.8064724,
    40.6591314
  ],
  [
    -73.8187891,
    40.6597174
  ],
  [
    -73.8215786,
    40.6551595
  ],
  [
    -73.8198118,
    40.6497464
  ],
  [
    -73.7800722,
    40.6302726
  ],
  [
    -73.7680559,
    40.6271458
  ],
  [
    -73.7544088,
    40.6443412
  ],
  [
    -73.7896852,
    40.6649177
  ],
  [
    -73.787235,
    40.6828082
  ],
  [
    -73.767143,
    40.7687155
  ],
  [
    -73.8200147,
    40.7941922
  ],
  [
    -73.8299711,
    40.7505119
  ],
  [
    -73.833704,
    40.7334702
  ],
  [
    -73.8271809,
    40.7235186
  ],
  [
    -73.8313866,
    40.7169484
  ],
  [
    -73.8396263,
    40.7278767
  ],
  [
    -73.8453707,
    40.7342087
  ],
  [
    -73.846229,
    40.7395416
  ],
  [
    -73.8367877,
    40.7461096
  ],
  [
    -73.8567004,
    40.7580083
  ],
  [
    -73.8614158,
    40.7636924
  ],
  [
    -73.8635689,
    40.7670438
  ],
  [
    -73.8568312,
    40.772309
  ],
  [
    -73.8761431,
    40.7812135
  ],
  [
    -73.883782,
    40.772829
  ],
  [
    -73.8962562,
    40.7735302
  ],
  [
    -73.9158102,
    40.7842268
  ],
  [
    -73.9244758,
    40.7753761
  ],
  [
    -73.9327156,
    40.7749211
  ],
  [
    -73.9326297,
    40.7696561
  ],
  [
    -73.9465263,
    40.7554904
  ],
  [
    -73.9545201,
    40.7433356
  ],
  [
    -73.952546,
    40.739954
  ],
  [
    -73.9532756,
    40.7381331
  ],
  [
    -73.9589833,
    40.7364422
  ],
  [
    -73.9601652,
    40.727616
  ],
  [
    -73.9569768,
    40.7243281
  ],
  [
    -73.9587588,
    40.7232679
  ],
  [
    -73.9609811,
    40.7240538
  ],
  [
    -73.963407,
    40.7195912
  ],
  [
    -73.9661051,
    40.7155458
  ],
  [
    -73.9672327,
    40.7089343
  ],
  [
    -73.9660018,
    40.7058927
  ],
  [
    -73.960673,
    40.70094
  ],
  [
    -73.9744601,
    40.6987113
  ],
  [
    -73.9934975,
    40.7039579
  ],
  [
    -73.9941616,
    40.7040874
  ],
  [
    -73.9992345,
    40.7080877
  ],
  [
    -73.9960957,
    40.7093036
  ],
  [
    -73.9855385,
    40.7104097
  ],
  [
    -73.9771271,
    40.712947
  ],
  [
    -73.9715481,
    40.7283641
  ],
  [
    -73.975668,
    40.7362668
  ],
  [
    -73.9625218,
    40.7550629
  ],
  [
    -73.9428101,
    40.7761631
  ],
  [
    -73.944773,
    40.7824995
  ],
  [
    -73.9362502,
    40.7913577
  ],
  [
    -73.9296837,
    40.7991354
  ],
  [
    -73.9293836,
    40.8008414
  ],
  [
    -73.9201138,
    40.7968455
  ],
  [
    -73.9162515,
    40.8023682
  ],
  [
    -73.8536449,
    40.8420782
  ],
  [
    -73.8763042,
    40.8724595
  ],
  [
    -73.9190478,
    40.8522068
  ],
  [
    -73.9303775,
    40.8338962
  ],
  [
    -73.9307208,
    40.8219461
  ],
  [
    -73.9272876,
    40.8083048
  ],
  [
    -73.9167931,
    40.8030057
  ],
  [
    -73.9170554,
    40.8018462
  ],
  [
    -73.9188525,
    40.7992494
  ],
  [
    -73.9200756,
    40.7977387
  ],
  [
    -73.9246939,
    40.799153
  ],
  [
    -73.9295513,
    40.8012015
  ],
  [
    -73.9310685,
    40.8038989
  ],
  [
    -73.9351884,
    40.8091612
  ],
  [
    -73.9343611,
    40.815482
  ],
  [
    -73.9354865,
    40.8308317
  ],
  [
    -73.9356058,
    40.8352063
  ],
  [
    -73.9302414,
    40.8453035
  ],
  [
    -73.9235687,
    40.8560522
  ],
  [
    -73.9222177,
    40.8597823
  ],
  [
    -73.9123901,
    40.8723421
  ],
  [
    -73.9234623,
    40.873413
  ],
  [
    -73.9272818,
    40.8756196
  ],
  [
    -73.9287071,
    40.8761965
  ]
]]);

export const App = () => {

  const [reveal, setReveal] = React.useState(false);
  
  const [originLat, setOriginLat] = React.useState(40.717291);
  const [originLng, setOriginLng] = React.useState(-73.9952561);

  const [destinationLat, setDestinationLat] = React.useState(40.691352);
  const [destinationLng, setDestinationLng] = React.useState(73.983684);
  React.useEffect( () => {
    const points = randomPointsOnPolygon(numberOfPoints, NYCPoly);
    const [originLat, originLng] = points[0].geometry.coordinates;
    const [destinationLat, destinationLng] = points[1].geometry.coordinates;
    setOriginLat(originLng);
    setOriginLng(originLat);
    setDestinationLat(destinationLng);
    setDestinationLng(destinationLat);
    // TODO: Reversed the points by accident
  }, [])

  return (
    <ChakraProvider theme={theme}>
      <SimpleGrid gridTemplateColumns={'60% 40%'} >
        <TrainMap
          reveal={reveal}
          originLat={originLat}
          originLng={originLng}
          destinationLat={destinationLat}
          destinationLng={destinationLng}/>
        <Flex
          height={'100vh'}
          width={'100%'}
          direction={'column'}
          alignItems='center'
          justifyContent={'center'}
          >
            <ColorModeSwitcher m={'2vh'}/>
            <Text as='b' fontSize={50} m='1vh' textAlign={'center'}>
              Train Game
            </Text>
            <Text as='b' fontSize={20} m='1vh' textAlign={'center'}>
              Happy Birthday Derek!
            </Text>
            <Button m={'10px'} colorScheme='blue' size='lg' marginTop={'10vh'} onClick={() => setReveal(true)}>
              Reveal
            </Button>
            {/* <OrderedList marginTop={'10vh'} marginBottom={'10vh'} marginLeft={'10'} marginRight={'10'}>
              <ListItem>Lorem ipsum dolor sit amet</ListItem>
              <ListItem>Consectetur adipiscing elit</ListItem>
              <ListItem>Integer molestie lorem at massa</ListItem>
              <ListItem>Facilisis in pretium nisl aliquet</ListItem>
            </OrderedList> */}
        </Flex>
    </SimpleGrid>    
    </ChakraProvider>
  )
}
